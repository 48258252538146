import React, { useState, useEffect, useRef } from "react"
import Link from "next/link"
import _get from "lodash/get"
import {
  getPDPUrl,
  sanitizeTextForAnalytics,
  getUserPersona,
} from "@/utils/helper"
import { withTranslation } from "react-i18next"
import useWindowResize from "@/hooks/useWindowResize"

import Highlight from "@/components/core/Highlight/Highlight"
import ProductSocialShare from "@/components/Shared/ProductSocialShare/v1/ProductSocialShare"
import shareIcon from "@/public/icons/share.svg"
import MissingImage from "@/public/images/image-missing.png"
import { getConfig } from "@/constants/config"
import TechAccordion from "@/components/Search/v1/TechnicalTile/TechAccordion"
import styles from "@/components/Search/v1/TechnicalTile/index.module.scss"

const TechnicalTile = props => {
  const { data, currFilters, page, fullWidth, staticTexts, highlightText, t } =
    props
  const {
    share = "",
    certifications: certificationsTxt = "",
    collection: collectionTxt = "",
    category: categoryTxt = "",
    discontinuedText = "",
  } = staticTexts
  const [width] = useWindowResize()
  const [showSocialLink, setShowSocialLink] = useState(false)
  const persona = getUserPersona()

  // get product details
  const masterCustomerFacingSKU = _get(data, "masterCustomerFacingSKU_s", "")
  const masterSKU = _get(data, "masterSKU_s", "")
  const sku = masterCustomerFacingSKU ? masterCustomerFacingSKU : masterSKU
  const discontinued = _get(data, "productRegionAllVariantDiscontinued_b", "")
  const discontinuedDate = _get(data, "RegionDiscontinuedDate_dt", "")
  const productName = _get(data, "productName_s", "")
  const brandName = _get(data, "ProductBrandName_s", "")
  const category = _get(data, "Product_Category", "")
  const collection = _get(data, "ProductBrandNameDisplay_s", "")
  const description = _get(data, "ProductDescriptionProductShort_s", "")
  const watersense = _get(data, "ProductSustainability_s", "")
  const adaCompliant = _get(data, "ProductADACompliant_s", "")
  const [pdpUrl, setpdpUrl] = useState("")
  useEffect(() => {
    getPDPUrl(category, data.slug_s).then(ret => setpdpUrl(ret))
  }, [data])

  const badgeVal = _get(data, `priceList.${persona}.saleOffer_s`, "")
  const discountedPrice = _get(data, `priceList.${persona}.finalPrice_d`, 0)
  const price = _get(data, `priceStartingAt.${persona}_d`, 0)
  const isDiscountedPrice = _get(
    data,
    `priceList.${persona}.discountedPrice_d`,
    0
  )
  const discountPercent =
    badgeVal && isDiscountedPrice
      ? 100 - Math.round((isDiscountedPrice / price) * 100)
      : 0
  const discountPriceState =
    badgeVal && isDiscountedPrice
      ? "percentOff|" + discountPercent
      : "regular price"
  const productIsRetail =
    _get(data, "ProductIsRetail", "") === "true" ? true : false
  const isSuperSku = _get(data, "comboSKUAvailable_b", null) ? true : false
  const discountAmount = discountPercent
    ? Number((price - isDiscountedPrice).toFixed(2))
    : 0
  const productSaleable = productIsRetail ? false : true
  const productRoom = _get(data, "category1_s", "n/a")
  const productCollectionsName = _get(data, "ProductBrandNameDisplay_s", "n/a")
  const tileTitle = _get(data, "Tile_Title", "")
  const productStatus =
    discontinued &&
    discontinuedDate &&
    Date.parse(discontinuedDate) < Date.now()
      ? "out of stock"
      : "in stock"

  // check for product images
  let imageUrl = ""
  if (
    data[`Color.${persona}.Details_ss`] &&
    data[`Color.${persona}.Details_ss`].length &&
    currFilters
  ) {
    const colorFilter = currFilters.find(
      q =>
        q.facet === "Color.Finish" ||
        q.facet === "Color" ||
        q.facet === "Finish"
    )
    const selColors = colorFilter?.value
    if (selColors?.length > 0) {
      const color = selColors[0]
      const productVariantImages = data[`Color.${persona}.Details_ss`]
      let colorVariants = productVariantImages.find(img => {
        const imgDetails = img.split("|")
        return color === imgDetails[1]
      })
      if (colorVariants) {
        colorVariants = colorVariants.split("|")
        imageUrl = colorVariants[5]
      }
    }
  }
  if (!imageUrl && data["productImages.url_s"]) {
    imageUrl = data["productImages.url_s"]
  } else if (!imageUrl && data["productImages.url_ss"]) {
    imageUrl = data["productImages.url_ss"][0]
  } else if (!imageUrl) {
    imageUrl = MissingImage?.src
  }
  // check for certificates
  const certificates = []
  adaCompliant === "Yes" && certificates.push("ADA")
  watersense && certificates.push(watersense)
  let certifications = ""
  certificates.forEach((item, i) => {
    certifications += item
    if (i !== certificates.length - 1) certifications += ", "
  })

  useEffect(() => {
    window.wow?.sync()
  }, [])

  const designFiles = {
    twoDimension: {
      plan: [],
      front: [],
      side: [],
    },
    threeDimension: [],
    bim: [],
    cutout: [],
  }
  const info = useRef({})

  useEffect(() => {
    getConfig().then(async config => {
      const brandName = await _get(config, "general.siteName", "")
      info.current = {
        brandName: brandName,
      }
    })
  }, [])

  // check for design files
  data["ProductResource.DXFPlanView.resourceFullWebURL_ss"] &&
    designFiles.twoDimension.plan.push({
      type: "DXF",
      url: data["ProductResource.DXFPlanView.resourceFullWebURL_ss"][0],
      id: "2D-plan-DXF",
    })
  data["ProductResource.DWGPlanView.resourceFullWebURL_ss"] &&
    designFiles.twoDimension.plan.push({
      type: "DWG",
      url: data["ProductResource.DWGPlanView.resourceFullWebURL_ss"][0],
      id: "2D-plan-DWG",
    })
  data["ProductResource.DXFFrontView.resourceFullWebURL_ss"] &&
    designFiles.twoDimension.front.push({
      type: "DXF",
      url: data["ProductResource.DXFFrontView.resourceFullWebURL_ss"][0],
      id: "2D-front-DXF",
    })
  data["ProductResource.DWGFrontView.resourceFullWebURL_ss"] &&
    designFiles.twoDimension.front.push({
      type: "DWG",
      url: data["ProductResource.DWGFrontView.resourceFullWebURL_ss"][0],
      id: "2D-front-DWG",
    })
  data["ProductResource.DXFSideView.resourceFullWebURL_ss"] &&
    designFiles.twoDimension.side.push({
      type: "DXF",
      url: data["ProductResource.DXFSideView.resourceFullWebURL_ss"][0],
      id: "2D-side-DXF",
    })
  data["ProductResource.DWGSideView.resourceFullWebURL_ss"] &&
    designFiles.twoDimension.side.push({
      type: "DWG",
      url: data["ProductResource.DWGSideView.resourceFullWebURL_ss"][0],
      id: "2D-side-DWG",
    })

  data["ProductResource.3DDXFSymbol.resourceFullWebURL_ss"] &&
    designFiles.threeDimension.push({
      type: "DXF",
      url: data["ProductResource.3DDXFSymbol.resourceFullWebURL_ss"][0],
      id: "3D-DXF",
    })
  data["ProductResource.3DDWGSymbol.resourceFullWebURL_ss"] &&
    designFiles.threeDimension.push({
      type: "DWG",
      url: data["ProductResource.3DDWGSymbol.resourceFullWebURL_ss"][0],
      id: "3D-DWG",
    })
  data["ProductResource.3DSketchup.resourceFullWebURL_ss"] &&
    designFiles.threeDimension.push({
      type: "SKP",
      url: data["ProductResource.3DSketchup.resourceFullWebURL_ss"][0],
      id: "3D-SKP",
    })
  data["ProductResource.3DOBJ.resourceFullWebURL_ss"] &&
    designFiles.threeDimension.push({
      type: "OBJ",
      url: data["ProductResource.3DOBJ.resourceFullWebURL_ss"][0],
      id: "3D-OBJ",
    })
  data["ProductResource.3D3DS.resourceFullWebURL_ss"] &&
    designFiles.threeDimension.push({
      type: "3DS",
      url: data["ProductResource.3D3DS.resourceFullWebURL_ss"][0],
      id: "3D-3DS",
    })

  data["ProductResource.3DRevit.resourceFullWebURL_ss"] &&
    designFiles.bim.push({
      type: "RFA",
      url: data["ProductResource.3DRevit.resourceFullWebURL_ss"][0],
      id: "RFA",
    })
  data["ProductResource.CutOutDXF.resourceFullWebURL_ss"] &&
    designFiles.cutout.push({
      type: "DXF",
      url: data["ProductResource.CutOutDXF.resourceFullWebURL_ss"][0],
      id: "cutout",
    })

  const getProductNavigationAnalytics = link => {
    if (page === "technicalspecs") {
      return JSON.stringify({
        eventAction: "technical specs:main content area:product navigation",
        eventName: "technical specs:main content area:product navigation",
        eventType: "navigation",
        eventMsg: "n/a",
        eventStatus: "n/a",
        internalLinkName: sanitizeTextForAnalytics(productName),
        internalLinkPosition: "technical specs",
        internalLinkType: "technical specs:navigation",
        internalLinkZoneName: "technical specs:main content area",
        internalLinkURL:
          (link.indexOf("http") === -1 ? window.location.origin : "") + link,
        clickInternalLinks: "true",
      })
    }
    return JSON.stringify({
      eventAction: "search results product navigation",
      eventName: "search results product navigation",
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: sanitizeTextForAnalytics(productName),
      internalLinkPosition: "search results",
      internalLinkType: "search results:technical tiles",
      internalLinkZoneName: "search results:technical:main content area",
      internalLinkURL:
        (link.indexOf("http") === -1 ? window.location.origin : "") + link,
      clickInternalLinks: "true",
    })
  }

  const productInfo = JSON.stringify({
    productInfo: {
      productBrand: info.current.brandName?.toLowerCase(),
      description: sanitizeTextForAnalytics(description) || "n/a",
      productID: sku || "n/a",
      productCategory: sanitizeTextForAnalytics(category) || "n/a",
      productName: sanitizeTextForAnalytics(productName) || "n/a",
      productRoom: sanitizeTextForAnalytics(productRoom || category) || "n/a",
      productCollectionsName:
        sanitizeTextForAnalytics(productCollectionsName || category) || "n/a",
      globalPromotionPrice:
        discountPercent && discountAmount
          ? Number((price - discountAmount).toFixed(2))
          : price,
      priceState: discountPriceState,
      productBasePrice: price,
      productColor: "n/a",
      productSalePrice: discountedPrice ? discountedPrice : price,
      productSaleable: productSaleable,
      productStatus: productStatus,
      productSuperSku: isSuperSku,
      productTileName: sanitizeTextForAnalytics(tileTitle) || "n/a",
      defaultImageName: imageUrl || "n/a",
      globalOffer: badgeVal ? badgeVal : "n/a",
      globalDiscount: discountPercent ? discountAmount : 0,
      productPartnerBuyNow: productIsRetail,
      pdpType: "regular finished goods",
      productFindingMethod: "n/a",
      productCouponCode: "n/a",
      productCoupondiscount: 0,
      frequency: "n/a",
      isRecommended: "n/a",
      isSendNow: "n/a",
      isSubscription: "n/a",
      quantity: 1,
      ratings: "n/a",
      numberOfReviews: "n/a",
    },
  })

  const shareEventInfo = (needsJSON = false) => {
    let eventInfo = {
      eventAction: "search results:technical:main content area:share",
      eventName: "search results:technical:main content area:share",
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: "share",
      internalLinkPosition: "search results",
      internalLinkType: "search results:share",
      internalLinkZoneName: "search results:technical:main content area",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
    if (page === "technicalspecs") {
      eventInfo = {
        eventAction: "technical specs:main content area:share",
        eventName: "technical specs:main content area:share",
        eventType: "navigation",
        eventMsg: "n/a",
        eventStatus: "n/a",
        internalLinkName: "share",
        internalLinkPosition: "technical specs",
        internalLinkType: "technical specs:share",
        internalLinkZoneName: "technical specs:main content area",
        internalLinkURL: "n/a",
        clickInternalLinks: "true",
      }
    }
    if (needsJSON) {
      eventInfo.custom = productInfo
      return eventInfo
    }
    return JSON.stringify(eventInfo)
  }

  const handleShare = e => {
    e.stopPropagation()
    e.preventDefault()
    setShowSocialLink(true)
  }

  return (
    <div className={styles.technicalTileWrapper}>
      <div
        className={`technical-tile ${
          fullWidth
            ? "--full-width technical-tile__grid-hidefilter"
            : "technical-tile__grid-showfilter"
        } wow animate__fadeInUp`}
      >
        <div className="technical-tile__image position-relative">
          {width <= 992 ? (
            <div
              className="technical-tile__add-share--mobile gbh-data-layer"
              data-gbh-data-layer-custom={productInfo}
              data-gbh-data-layer={shareEventInfo()}
            ></div>
          ) : null}
          <div
            className="technical-tile__add-share gbh-data-layer"
            data-gbh-data-layer-custom={productInfo}
            data-gbh-data-layer={shareEventInfo()}
          >
            <span
              tabIndex="0"
              role="button"
              aria-label={t("kf.plp.label.share", { val: productName })}
              onClick={handleShare}
            >
              <img
                tabIndex="0"
                role="button"
                aria-hidden
                src={shareIcon.src}
                alt={share}
              />
              <div className="share-section">
                <ProductSocialShare
                  showModal={showSocialLink}
                  id={`kf-technical-tile-${data.id}`}
                  onClose={() => setShowSocialLink(false)}
                  analyticData={shareEventInfo(true)}
                  disableInstagram
                  url={pdpUrl}
                />
              </div>
            </span>
          </div>
          <Link
            href={pdpUrl}
            data-gbh-data-layer={getProductNavigationAnalytics(pdpUrl)}
            data-gbh-data-layer-custom={productInfo}
            className="d-block technical-tile__image--product-image gbh-data-layer"
          >
            <div>
              <img src={imageUrl} alt={productName} />
            </div>
          </Link>
        </div>
        {width <= 992 ? (
          <div className="technical-tile__details">
            {discontinued &&
            discontinuedDate &&
            Date.parse(discontinuedDate) < Date.now() ? (
              <div className="technical-tile__badge">
                <div className="technical-tile__discontinued">
                  {discontinuedText}
                </div>
              </div>
            ) : null}
            <Link
              href={pdpUrl}
              className="gbh-data-layer"
              data-gbh-data-layer={getProductNavigationAnalytics(pdpUrl)}
            >
              <h3 role="heading" aria-level="3">
                {brandName}
              </h3>
            </Link>
            <div className="technical-tile__brand-description">
              <Highlight text={description} highlight={highlightText} />
            </div>
            <div className="technical-tile__sku">
              <span>{sku}</span>
            </div>
          </div>
        ) : null}
        <div
          className={`technical-tile__collapsible ${
            fullWidth
              ? "technical-tile__collapsible-fullWidth-grid"
              : "technical-tile__collapsible-grid"
          }`}
        >
          {width > 992 &&
          discontinued &&
          discontinuedDate &&
          Date.parse(discontinuedDate) < Date.now() ? (
            <div className="technical-tile__badge">
              <p className="technical-tile__discontinued">{discontinuedText}</p>
            </div>
          ) : null}
          <div className="technical-tile__specs-container">
            {width > 992 ? (
              <div className="technical-tile__details">
                <Link
                  href={pdpUrl}
                  className="gbh-data-layer"
                  data-gbh-data-layer={getProductNavigationAnalytics(pdpUrl)}
                >
                  <h3 role="heading" aria-level="3">
                    {brandName}
                  </h3>
                </Link>
                <div className="technical-tile__brand-description">
                  <Highlight text={description} highlight={highlightText} />
                </div>
                <div className="technical-tile__sku">
                  <span>{sku}</span>
                </div>

                <div
                  className="technical-tile__add-share--mobile gbh-data-layer"
                  data-gbh-data-layer-custom={productInfo}
                  data-gbh-data-layer={shareEventInfo()}
                ></div>
              </div>
            ) : null}
            {!(
              designFiles.twoDimension.plan.length === 0 &&
              designFiles.twoDimension.front.length === 0 &&
              designFiles.twoDimension.side.length === 0 &&
              designFiles.threeDimension.length === 0
            ) ? (
              <>
                <div className="technical-tile__specs">
                  {category ? (
                    <div>
                      <span
                        className="technical-tile__specs-subtitle"
                        role="heading"
                        aria-level="4"
                      >
                        {categoryTxt}
                      </span>
                      <span className="technical-tile__specs-content">
                        {category}
                      </span>
                    </div>
                  ) : null}
                  {collection ? (
                    <div>
                      <span
                        className="technical-tile__specs-subtitle"
                        role="heading"
                        aria-level="4"
                      >
                        {collectionTxt}
                      </span>
                      <span className="technical-tile__specs-content">
                        {collection}
                      </span>
                    </div>
                  ) : null}
                  {certificates.length > 0 ? (
                    <div>
                      <span
                        className="technical-tile__specs-subtitle"
                        role="heading"
                        aria-level="4"
                      >
                        {certificationsTxt}
                      </span>
                      <span className="technical-tile__specs-content">
                        {certifications}
                      </span>
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          {width <= 992 || width > 1140 ? (
            <div>
              <TechAccordion
                {...props}
                sku={sku}
                designFiles={designFiles}
                productInfo={productInfo}
              />
            </div>
          ) : null}
        </div>
        {width > 992 && width <= 1140 ? (
          <div className="technical-tile__collapsible wide-collapsible technical-tile__collapsible-fullWidth-grid">
            <TechAccordion
              {...props}
              sku={sku}
              designFiles={designFiles}
              productInfo={productInfo}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default withTranslation()(TechnicalTile)
