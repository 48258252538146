import { pushAnalyticsObjToDataLayer } from "@/utils/helper"

export const technicalSpecsServicePartsAnalytics = (
  pageType,
  zone,
  productInfo
) => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:main content area:${zone}:interactive parts diagram`,
    eventMsg: "n/a",
    eventName: `${pageType}:main content area:${zone}:interactive parts diagram`,
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "service parts diagram",
    internalLinkPosition: pageType,
    internalLinkType: `${pageType}:navigation`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageType}:${zone}`,
  }

  pushAnalyticsObjToDataLayer(
    eventInfo,
    "cmp:click",
    {},
    typeof productInfo === "string" ? JSON.parse(productInfo) : productInfo
  )
}
